.chat_container_bg {
  border: 1px solid var(--Colour-Gray-Gray---07, #333333);
  background: linear-gradient(
    83.98deg,
    rgba(42, 42, 42, 0.33) 2.84%,
    rgba(36, 36, 36, 0.52) 97.18%
  );
}

.send_chat_bg {
  background: linear-gradient(83.98deg, #252525 2.84%, #242424 97.18%);
}

/* .reply_chat_bg {
} */

.chat_reply_logo {
  /* background: linear-gradient(
    83.98deg,
    rgba(31, 31, 31, 0.33) 2.84%,
    rgba(20, 20, 20, 0.52) 97.18%
  ); */

  background: linear-gradient(180deg, #515151 0%, rgba(71, 71, 71, 0.23) 100%);

  /* border: 0.44px solid var(--Colour-Gray-Gray---06, #737373); */
  box-shadow: 0px -17.86px 5.1px 0px #1e1e1e7d inset;

  box-shadow: 0px 5.74px 1.91px 0.64px #37373726 inset;
}

.prompt_container_bg {
  background: linear-gradient(83.98deg, #222222 2.84%, #1e1e1e 97.18%);
}

::-webkit-scrollbar {
  width: 0.5em;
  background: #060608;
}

::-webkit-scrollbar-track {
  background: #060608;
  margin-block: 1em;
}
::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 100vw;
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: #333 #333 !important;
    scrollbar-width: 30px !important;
  }
}
